.SimpleSearchSearchBtn {
    height: 28px;
    width: 62px;
    background: rgba(247,247,247,1);
    border-radius: 0 2px 2px 0;
    border: 1px solid rgba(217,217,217,1);
    cursor: pointer;
}
.SimpleSearchSearchBtn:hover {
    background-color: #ECECEC;
}
.SimpleSearchSearchBtn:active {
    background: rgba(236,236,236,1);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
    border-radius: 0 2px 2px 0;
    border: 1px solid rgba(217,217,217,1);
}

.simpleSearchContainer .u-form-control {
    width: 224px!important;
    height: 28px!important;
    margin-left: 14px;
    border-right: none;
}

.simpleSearchContainer .u-form-control ~ span i {
    position: relative;
    top: 0px!important;
}
