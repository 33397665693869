label {
  font-weight: bold;
  display: block; }

.containers-IndexPage-IndexPage__navbarHeader--1Hr1c {
  margin-bottom: 0px;
  height: 50px;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 0 17%;
  position: absolute;
  top: 0;
  width: 100%; }

.containers-IndexPage-IndexPage__NewFunctionDesc1--1TZQt {
  background-color: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .containers-IndexPage-IndexPage__NewFunctionDesc1--1TZQt > div {
    width: 25%; }

.containers-IndexPage-IndexPage__AppAddExp--2MYrG {
  background-color: transparent; }
  .containers-IndexPage-IndexPage__AppAddExp--2MYrG .containers-IndexPage-IndexPage__main--2un4u {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 15%; }
    .containers-IndexPage-IndexPage__AppAddExp--2MYrG .containers-IndexPage-IndexPage__main--2un4u .containers-IndexPage-IndexPage__left--20BCQ {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .containers-IndexPage-IndexPage__AppAddExp--2MYrG .containers-IndexPage-IndexPage__main--2un4u .containers-IndexPage-IndexPage__right--1OJ2v {
      -webkit-box-flex: 2;
      -webkit-flex: 2;
          -ms-flex: 2;
              flex: 2; }

.containers-IndexPage-IndexPage__btn--1ehm0 {
  white-space: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  color: #434a54;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  height: 40px;
  background: -webkit-gradient(linear, left top, left bottom, from(#dfecf2), to(#bbd6e3));
  background: -webkit-linear-gradient(#dfecf2 0%, #bbd6e3 100%);
  background: linear-gradient(#dfecf2 0%, #bbd6e3 100%);
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: calc(50% - 5px);
  margin-top: 10px; }
