.components_advanced-Input-input__container--1b8wJ {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .components_advanced-Input-input__container--1b8wJ .components_advanced-Input-input__title--2yBZO {
    width: 90px;
    line-height: 28px;
    color: #666666;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
  .components_advanced-Input-input__container--1b8wJ > input {
    height: 28px;
    max-width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1; }
  .components_advanced-Input-input__container--1b8wJ .components_advanced-Input-input__numTypeUnit--3r9DY, .components_advanced-Input-input__container--1b8wJ > i {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-weight: bold;
    color: #289cf2; }
  .components_advanced-Input-input__container--1b8wJ > i {
    right: 10px;
    color: #666666;
    font-weight: normal;
    cursor: pointer; }

.components_advanced-Input-input__warnMsg--254PK {
  color: #E60012;
  line-height: 1;
  margin-left: 100px;
  margin-top: 5px; }
