.imgItem img {
  width: 100%;
}

/* 轮播图上的描述 */
.describe {
  position: absolute;
  top: 25%;
  left: 12%;
}
.img1 .describe1 {
  color: #333333;
  font-weight: 400;
  color: rgb(229, 57, 53);
}

.img1 .describe1 svg {
  font-size: 180px;
  position: absolute;
  top: -102%;
  margin-left: 10px;
}

.img1 button a {
  color: white;
}

.img2 .describe2 {
  color: #4769c2;
  font-size: 26px;
  font-weight: 400;
}

.img2 button a {
  color: #175cff;
}

.img4 .describe4 {
  color: #333333;
  font-weight: 400;
}

.img4 .describe4 svg {
  font-size: 140px;
  position: absolute;
  top: -71%;
  margin-left: -5px;
}

.img4 .describe4.p {
  display: block;
}

.img4 .describe4 span {
  position: relative;
  left: 185px;
}

.img4 button a {
  color: white;
}

.imgItem h2 {
  height: 55px;
  font-size: 26px;
  font-family: MicrosoftYaHei;
  line-height: 55px;
}

.imgItem p {
  position: absolute;
  top: 22%;
  left: 12%;
  color: rgba(194, 194, 194, 0.8);
  margin-top: 70px;
  width: 524px;
  height: 19px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 25px;
}

.imgItem p.second {
  top: 28%;
}

.imgItem button {
  position: absolute;
  top: 62%;
  margin-top: 20px;
  left: 12%;
  width: 93px;
  height: 26px;
  border-radius: 14px;
  font-size: 12px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.imgItem .describe .topBar {
  width: 100%;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.imgItem .describe .topBar svg.icon {
  font-size: 180px;
  position: relative;
  top: -105%;
  margin-left: 0px;
}

.imgItem .describe .topBar span {
  position: relative;
  left: 0;
}

/* 第三张轮播图字体颜色 */
.redcolor {
  color: #373d41;
}

/* banner 容器 */
#customized-pagination {
  position: relative;
  margin: auto;
  width: 100%;
  min-height: 200px;
  min-width: 1000px;
}

/* 小按钮样式 */
.swiper-pagination-bullet {
  width: 16px;
  height: 5px;
  background: #cccccc 30%;
  border-radius: 5px;
}

.swiper-pagination-bullet-active {
  width: 36px;
  height: 5px;
  background: #e14c46;
  border-radius: 5px;
}

.headcolor{
  color: #AAAAAA;
 font-size: 14px;
 height: 68px;
 line-height: 68px;
 text-indent: 1em;
}
