.detailSearch {
    position: relative;
    padding: 12px 22px 0 28px;
    background-color: #F7F7F7;
    border-radius:8px;
    margin-top: 12px;

    animation-name: showSearchFrom_TopToBottom;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    /* Firefox: */
    -moz-animation-name: showSearchFrom_TopToBottom;
    -moz-animation-duration: 0.2s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: 1;
    /* Safari 和 Chrome: */
    -webkit-animation-name: showSearchFrom_TopToBottom;
    -webkit-animation-duration: 0.2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    /* Opera: */
    -o-animation-name: showSearchFrom_TopToBottom;
    -o-animation-duration: 0.2s;
    -o-animation-timing-function: linear;
    -o-animation-iteration-count: 1;
}

/*显示高级搜索的动画效果－－－从左往右*/
@keyframes showSearchFrom_LeftToRight
{
    from{

        -webkit-transform: translateX(-100%);

                transform: translateX(-100%);

    }

    to{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }

}

@-webkit-keyframes showSearchFrom_LeftToRight /* Safari and Chrome */
{
    from{

        -webkit-transform: translateX(-100%);

                transform: translateX(-100%);

    }

    to{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
}

/*显示高级搜索的动画效果－－－从上往下*/
@keyframes showSearchFrom_TopToBottom
{
    from{

        -webkit-transform: translateY(-70%);

                transform: translateY(-70%);
        opacity: 0;

    }

    to{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1;
    }

}

@-webkit-keyframes showSearchFrom_TopToBottom /* Safari and Chrome */
{
    from{

        -webkit-transform: translateY(-70%);

                transform: translateY(-70%);
        opacity: 0;

    }

    to{
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
        opacity: 1;
    }
}

.arrow{
    position: absolute;
    border: 10px solid transparent;
    border-bottom: 15px solid #F7F7F7;
    left: 434px;
    top: -21px;
    pointer-events: none;
}
.search-items-container{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    position: relative;
}

.detailSearch .u-form-control {
    width: 200px!important;
    height: 28px!important;
}

.detailSearch .u-form-control ~ span i {
    position: relative;
    top: 0px!important;
}