.title-message{
	padding: 17px 0 13px 0;
	border-bottom: 1px solid #DDD;
	font-size: 14px;
}
.title-message .return{
	color: #00B39E;
	margin-left: 21px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAPCAYAAAA2yOUNAAAAAXNSR0IArs4c6QAAAUtJREFUKBV10T9Lw0AYBvDnYkJSNbUKOoi7q4iTk5OCOChCXSRQ7OJgBdHFKZ/ASkGhaCv+KZWIFqmLWz+BiJ9AWscqQSiNRXu+b0rQ2ho4chd+eS73ROC/yznoh66vofl1p3Y1zl4IRugYAis0NNGBiuleSPUMQixD4gbuq6W0Id5CqjkfAFfw3FVYO7UfdJsxYRh5AouQMo9q2UJ0q84hLeSkB6CISwILBM7xXIshZnvBLgoKyQgM1aEH8wRO4FXiSCQ+AsB3BWp4kxJmaf4I920DUbvxG7TQZ+OUEp5oMYHI0O5fwOtWBddHY9CUAiVOQTaTeHjZhm03gxd6/IlTfMfSXBGaNk0wilFzBOOT9yiVfNheppMdRgh8iBkaWdTL6/yN7Yhjc4eDMHWugw4jc6hW4p2I4UUqjHAfN8+97XdHDPkPKCJDaalvPQBjcnNMPrQAAAAASUVORK5CYII=) no-repeat;
	padding-left: 18px;
	cursor: pointer;
}
.title-message .number{
	color: #666;
	margin-left: 50px;
}
.title-message .number .numb-cont{
	color: #333;
	margin-left: 10px;
}
.title-message .name{
	color: #666;
	margin-left: 40px;
}
.title-message .name .name-cont{
	color: #333;
	margin-left: 10px;
}
.chose-btn{
	width: 200px;
	height: 32px;
	border: 1px solid #D9D9D9;
	border-radius: 4px;
	margin: 20px 0 20px 30px;
	background-color: #fff;
}
.chose-cont{
	min-height: 535px;
	padding: 20px 0 0 30px;
	border-top: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	opacity: 50;
}
.btn{
	height: 94px;
	position: relative;
}
.btn>button{
	position: absolute;
	bottom: 30px;
	width: 108px;
	height: 30px;
	border-radius: 4px;
}
.btn .save-btn{
	right: 328px;
	background-color: #00B39E;
	color: #fff;
}
.btn .bad-btn{
	right: 200px;
	margin-left: 20px;
	border: 1px solid #00B39E;
	background-color: #fff;
	color: #00B39E;
}