.system-nav-item-arrow{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid red;
    -webkit-transform: translate(-50%,0);
            transform: translate(-50%,0);
}