.components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1; }
  .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__searchItem--3RtSy {
      width: 270px; }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__searchBtn--1BZSw {
      width: 62px;
      background: #f7f7f7;
      border-radius: 0px 2px 2px 0px;
      border: 1px solid #d9d9d9;
      text-align: center;
      line-height: 25px;
      border-left: none;
      cursor: pointer; }
      .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__searchBtn--1BZSw:hover {
        background: #ececec; }
      .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__searchBtn--1BZSw:active {
        background: #ececec;
        -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1); }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__switchComplex--3Rsl1::after {
      display: none; }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__switchSimple--1XZcv, .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__switchComplex--3Rsl1 {
      -webkit-align-self: center;
          -ms-flex-item-align: center;
              align-self: center;
      font-size: 12px;
      color: #3b8eee;
      margin-left: 24px;
      cursor: pointer;
      position: relative; }
      .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__switchSimple--1XZcv::after, .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__simpleSearch--3wT__ .components_advanced-TableSearchCpt-tableSearchCpt__switchComplex--3Rsl1::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border: 12px solid #F7F7F7;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: none;
        top: 24px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
  .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__complexSearch--1s5qL {
    background: #f7f7f7;
    border-radius: 8px;
    margin-top: 0;
    padding: 12px 22px 2px 28px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0; }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__complexSearch--1s5qL .components_advanced-TableSearchCpt-tableSearchCpt__complexSearchLi--3r-Kt {
      width: 300px; }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__complexSearch--1s5qL ul:first-child {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-align-content: space-between;
          -ms-flex-line-pack: justify;
              align-content: space-between;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__complexSearch--1s5qL ul:first-child li {
        -webkit-box-flex: 0;
        -webkit-flex: none;
            -ms-flex: none;
                flex: none;
        margin-bottom: 10px; }
    .components_advanced-TableSearchCpt-tableSearchCpt__tableSearchcontainer--jPnnW .components_advanced-TableSearchCpt-tableSearchCpt__complexSearch--1s5qL > div:last-child {
      padding-left: 20px; }
